var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.cpaNetworks.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.cpaNetworks.total,"loading":_vm.loading,"sort-by":"id","footer-props":_vm.footerProps},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage,"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.trans('buttons.cpaNetwork.newItem')))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialogLoaded)?_c('cpa-network-form',{attrs:{"options":_vm.options,"itemId":_vm.editedItem},on:{"saved":_vm.onSave,"close":_vm.close}}):_vm._e()],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-text-field',{attrs:{"label":_vm.trans('fields.common.label'),"clearable":""},model:{value:(_vm.filters.label),callback:function ($$v) {_vm.$set(_vm.filters, "label", $$v)},expression:"filters.label"}})],1),_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('user-autocomplete',{attrs:{"clearable":"","label":_vm.trans('fields.common.user')},model:{value:(_vm.filters.user_id),callback:function ($$v) {_vm.$set(_vm.filters, "user_id", $$v)},expression:"filters.user_id"}})],1),_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-select',{attrs:{"items":_vm.options.cpaNetworkScopes,"label":_vm.trans('fields.common.scope'),"multiple":true,"clearable":""},model:{value:(_vm.filters.scope),callback:function ($$v) {_vm.$set(_vm.filters, "scope", $$v)},expression:"filters.scope"}})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.apply')))]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""},on:{"click":_vm.clearFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.clear')))])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.created_at))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?[_c('user-link',{attrs:{"user":item.user}})]:_vm._e()]}},{key:"item.scope",fn:function(ref){
var item = ref.item;
return [(_vm.options.cpaNetworkScopesKeyed)?[_vm._v(_vm._s(_vm.options.cpaNetworkScopesKeyed[item.scope]))]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }